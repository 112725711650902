import axios from 'axios'

export default {

    async getOrderSignature(cartId, transactionDate) {
        try {
            let response = await axios.post("/payment/cart/" + cartId + "/signature", {transactionDate})
            return response ? response.data : null
        } catch (e) {
            throw 500
        }
    },

    async initiatePayment(cartId, payload) {
        try {
            let response = await axios.post("/payment/cart/" + cartId, payload)
            return response ? response.data : null
        } catch (e) {
            throw 500
        }
    },

    async validatePayment(cartId, result) {
        let response = await axios.post(`/payment/cart/${cartId}/capture`, result)
        return response.data ? response.data : null
    },

    async checkPaymentEligibility(cartId, provider) {

        const response = await axios.post(`/payment/cart/${cartId}/provider/${provider}/eligibility`)
        return response ? response.data : []

    },
}